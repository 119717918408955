<template>
  <div class="mail-all" v-if="!isProcessing">
    <mail-all />
  </div>
</template>

<script>
import MailAll from '@/components/mail/all.vue'

export default {
  name: 'mail_all',
  components: { MailAll },
  mounted () {
    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.mail-all {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
